<template>
  <div class="campus-page" :style="'background-image: url(' + pageBackground + ')'">
    <common-head></common-head>
    <div class="page-content">
      <div class="menu-panel">
        <swiper class="swiper-container" ref="mySwiper" :options="swiperOptions">
          <swiper-slide :class="[activeItem === item.m_id ? 'active' : '', 'swiper-slide']" v-for="item in pageInfo.course_list" :key="item.id">
            <img :src="item.icon" @click="toggleActive(item)"/>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev">
          <img src="../assets/img/left.png"/>
        </div>
        <div class="swiper-button-next">
          <img src="../assets/img/right.png"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommonHead from '@/components/common/CommonHead'
export default {
  name: 'Campus',
  components: {
    CommonHead
  },
  data () {
    return {
      pageBackground: '',
      activeItem: '',
      pageInfo: {
        m_id: '',
        title: '',
        logo: '',
        is_change: 2,
        background: '',
        watermark: '',
        course_list: []
      },
      // swiper
      swiperOptions: {
        slidesPerView: 4, // 显示个数
        direction: 'horizontal',
        spaceBetween: 40,
        pagination: {
          el: '.swiper-pagination'
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  },
  created () {
    this.getPageInfo()
  },
  methods: {
    async getPageInfo () {
      const { data: res } = await this.$http.get('/campus-info', { params: { m_id: this.$route.params.campus_id } })
      if (res.code === 200) {
        this.pageInfo.m_id = res.data.m_id
        this.pageInfo.title = res.data.title
        this.pageInfo.logo = res.data.logo
        this.pageInfo.is_change = res.data.is_change
        this.pageInfo.background = res.data.background
        this.pageBackground = res.data.background
        this.pageInfo.watermark = res.data.watermark
        this.pageInfo.course_list = res.data.course_list
        this.swiperOptions.slidesPerView = res.data.block_num
        window.localStorage.setItem('ischange', res.data.is_change)
        this.$nextTick(() => {
          this.swiper.params.slidesPerView = res.data.block_num
          this.swiper.update()
        })
        // 存储校区信息到vuex
        this.$store.commit('updateSchoolInfo', res.data)
      } else {
        this.$message.error(res.msg)
        await this.$router.push('/')
      }
    },
    // 点击进入专题
    toggleActive (item) {
      if (this.activeItem && this.activeItem !== item.m_id && parseInt(window.localStorage.getItem('ischange')) === 2) {
        this.activeItem = item.m_id
        this.pageBackground = item.background
      } else if (this.activeItem && this.activeItem === item.m_id && parseInt(window.localStorage.getItem('ischange')) === 2) {
        this.activeItem = ''
        this.pageBackground = this.pageInfo.background
        this.$router.push('/campus/' + this.$route.params.campus_id + '/special/' + item.m_id)
      } else if (parseInt(window.localStorage.getItem('ischange')) === 2) {
        this.activeItem = item.m_id
        this.pageBackground = item.background
      } else {
        this.$router.push('/campus/' + this.$route.params.campus_id + '/special/' + item.m_id)
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.$swiper
    }
  }
}
</script>

<style scoped>
.campus-page{
  width: 100%;
  height: 100vh;
  background-size: cover;
  position: relative;
}
.page-content{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  margin-bottom: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-panel{
  width: 90%;
  height: 100%;
  position: relative;
}
.swiper-container {
  width: 100%;
  height: auto;
  padding: 30px;
}
/deep/.swiper-wrapper{
  display: flex;
  align-items: center;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  height: auto;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  border-radius: 0.5em;
  cursor: pointer;
}
.swiper-slide.active{
  transform: scale(1.05);
  transition: all .3s;
  border: 4px solid #FBAE17;
  padding: 2px;
  background-color: rgba(255,255,255,0);
}
.swiper-slide img{
  width: 100%;
  height: 100%;
  border-radius: 0.5em;
}
.swiper-button-prev:after{
  content: none;
}
.swiper-button-prev{
  width: 5%;
  height: auto;
  left: 20px;
  cursor: pointer;
}
.swiper-button-prev img{
  width: 100%;
  height: 100%;
}
.swiper-button-next:after{
  content: none;
}
.swiper-button-next{
  width: 5%;
  height: auto;
  right: 20px;
  cursor: pointer;
}
.swiper-button-next img{
  width: 100%;
  height: auto;
}
</style>
