<template>
  <div class="common-header">
    <div class="left">
      <img :src="this.$store.state.schoolInfo.logo"/>
    </div>
    <div class="right">
      <div class="time">
        {{this.$store.state.schoolInfo.title}}----{{updatetime(nowDate)}}
      </div>
      <div class="logout">
        <div class="logout-btn" @click="logout">退出帐号</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommonHead',
  data () {
    return {
      headInfo: {
        logo: '',
        title: ''
      },
      // 当前时间
      nowDate: new Date()
    }
  },
  created () {
  },
  methods: {
    updatetime (value) {
      return this.$moment(value).format('LTS')
    },
    async logout () {
      const { data: res } = await this.$http.post('/logout', { m_id: this.$store.state.userInfo.m_id })
      if (res.code === 200) {
        this.$store.commit('clearUserInfo')
        this.$message.success('退出登录成功')
        await this.$router.push('/')
      }
    }
  },
  mounted () {
    const _this = this
    this.timer = setInterval(() => {
      _this.nowDate = new Date()
    }, 1000)
  },
  beforeDestroy () { // 生命周期实例销毁之前
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>

<style scoped>
.common-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 60px;
}
.common-header .left{
  width: 180px;
}
.left img{
  width: 100%;
}
.right .time{
  color: #FFFFFF;
  background-color: rgba(0,0,0,0.2);
  padding: 4px 10px;
  border-radius: 6px;
  font-size: 15px;
}
.right .logout{
  margin-top: 20px;
  text-align: right;
}
.logout-btn{
  background-color: #FBAE17;
  width: auto;
  padding: 4px 16px;
  color: #FFFFFF;
  font-size: 1rem;
  text-align: center;
  border-radius: 6px;
  cursor: pointer;
}
</style>
